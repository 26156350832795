<template>
  <b-nav>
    <b-nav-item @click="changeLocale(getLocale.locale)">
      <b-img
        :src="getLocale.img"
        height="14px"
        width="22px"
        :alt="getLocale.locale"
      />
      <span class="ml-50 text-body">{{ getLocale.name }}</span></b-nav-item>
  </b-nav>

  <!-- Multiple Locale Dropdown -->
  <!-- <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown> -->
</template>

<script>
// import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { BNav, BNavItem, BImg } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    // BNavItemDropdown,
    // BDropdownItem,
    BImg,
    BNav,
    BNavItem,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    // Incase Multiple Languages, find the current language
    // currentLocale() {
    //   return this.locales.find(l => l.locale === this.$i18n.locale)
    // },

    getLocale() {
      return this.locales.find(l => l.locale !== this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'ar',
        img: require('@/assets/images/flags/ar.png'),
        name: 'Arabic',
      },
    ]
    /* eslint-disable global-require */

    function changeLocale(selectedLocale) {
      this.$i18n.locale = selectedLocale
      store.commit('appConfig/TOGGLE_RTL', selectedLocale)
    }

    return {
      locales,
      changeLocale,
    }
  },
}
</script>

<style>

</style>
